import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { HREF_MINUT_HOME_GROUPS } from "src/constants/hrefs"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { ExternalLink } from "src/ui/Link/ExternalLink"

import NoHomesInPresetImage from "./images/no-homes-in-preset.svg"

export function NoHomesInHomeGroup({
  onClick,
  type,
}: {
  onClick: () => void
  type: THomeGroup["type"]
}) {
  const { t, langKeys } = useTranslate()

  const showBuildingCopy = type === "building"

  const title = showBuildingCopy
    ? t(langKeys.home_group_buildings_homes_empty_state_title)
    : t(langKeys.organizations_home_group_homes_empty_state_title)
  const description = showBuildingCopy
    ? t(langKeys.home_group_buildings_homes_empty_state_body)
    : t(langKeys.organizations_home_group_homes_empty_state_body)
  const buttonText = showBuildingCopy
    ? t(langKeys.home_group_buildings_homes_empty_state_action)
    : t(langKeys.organizations_home_group_homes_empty_state_action)

  return (
    <EmptyStateWithImage
      Image={NoHomesInPresetImage}
      title={title}
      description={
        <>
          {description}{" "}
          <ExternalLink href={HREF_MINUT_HOME_GROUPS}>
            {t(langKeys.learn_more)}
          </ExternalLink>
        </>
      }
      content={<MButton onClick={onClick}>{buttonText}</MButton>}
    />
  )
}
